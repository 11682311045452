const EmployerType = {
  CREATE_EMPLOYER: "CREATE_EMPLOYER",
  SET_EMPLOYER_LIST: "SET_EMPLOYER_LIST",
  UPDATE_EMPLOYER: "UPDATE_EMPLOYER",
  UPDATE_EMPLOYER_KYC: "UPDATE_EMPLOYER_KYC",
  GET_EMPLOYER_DETAILS: "GET_EMPLOYER_DETAILS",
};

export interface CreateEmployer {
  id: number;
}

export interface GetEmployer {
  search: string;
  limit: number;
  page: number;
  type: string;
}

export interface UpdateEmployer {
  id: number;
  name: string;
  email: string;
  mobile: any;
  no_of_employee: number;
  state: number;
  district: number;
  pincode: number;
  address: string;
}

export interface UpdateEmployerKYC {
  id: number;
  gst_no: any;
  gst_img: any;
  pancard_no: any;
  pancard_img: any;
  msme_no: any;
  msme_img: any;
  partnership_no: any;
  partnership_img: any;
  rc_no: any;
  rc_img: any;
  moa_no: any;
  moa_img: any;
  aoa_no: any;
  aoa_img: any;
  coi_no: any;
  coi_img: any;
  trust_no: any;
  trust_img: any;
}

export interface GetEmployerDetails {
  id: number;
}

export default EmployerType;
