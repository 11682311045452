import { Route, Routes } from "react-router-dom";
import Home from "../views/dashboard/Home";
import MemberList from "../views/member/MemberList";
import RegistrationList from "../views/registration/RegistrationList";
import NewJoining from "../views/member/NewJoining";
import Login from "../views/auth/Login";
import AboutUs from "../views/about_us/AboutUs";
import Certification from "../views/certification/Certification";
import Document from "../views/document/Document";
import ContactList from "../views/enquiry/ContactList";
import EnquiryList from "../views/enquiry/EnquiryList";
import EmployerList from "../views/employer/EmployerList";
import CompanyType from "../views/company_type/CompanyType";
import EmployerDetails from "../views/employer/EmployerDetails";
import EmployeeList from "../views/employee/EmployeeList";
import AttendanceList from "../views/attendance/AttendanceList";
import EmployeeWiseAttendanceList from "../views/attendance/EmployeeWiseAttendanceList";

function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Home />} />
      <Route path="/member-list" element={<MemberList />} />
      <Route path="/enquiry-list" element={<EnquiryList />} />
      <Route path="/registration-list" element={<RegistrationList />} />
      <Route path="/new-joining" element={<NewJoining />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/certification" element={<Certification />} />
      <Route path="/document" element={<Document />} />
      <Route path="/contact-list" element={<ContactList />} />
      <Route path="/employer-list" element={<EmployerList />} />
      <Route path="/company-type" element={<CompanyType />} />
      <Route path="/employer-details/:id" element={<EmployerDetails />} />
      <Route path="/employee-list" element={<EmployeeList />} />
      <Route path="/attendance-list" element={<AttendanceList />} />
      <Route
        path="/ema-wise-attendance-list/:id"
        element={<EmployeeWiseAttendanceList />}
      />
    </Routes>
  );
}

export default PageRoutes;
