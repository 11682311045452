function ToggleTab({ toggle, viewData }: any) {
  return (
    <div
      style={{
        display: toggle ? "block" : "none",
      }}>
      <div className="chat">
        <div className="chat-header clearfix">
          <img src={viewData?.profile_img} alt="avatar" />
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="chat-about">
                <div className="chat-with">{viewData?.name}</div>
                <div className="chat-num-messages">
                  <p className="mb-1">
                    <b>Designation: </b> {viewData?.designation}
                  </p>
                  <p className="mb-1">
                    <b>Email: </b> {viewData?.email}
                  </p>
                  <p className="mb-1">
                    <b>Mobile: </b> {viewData?.mobile}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="chat-about">
                <div className="chat-with">Address</div>
                <div className="chat-num-messages">
                  <p className="mb-1">
                    <b>Present Address: </b> {viewData?.present_address}
                  </p>
                  <p className="mb-1">
                    <b>State: </b> {viewData?.sName}
                  </p>
                  <p className="mb-1">
                    <b>District: </b> {viewData?.dName}
                  </p>
                  <p className="mb-1">
                    <b>Pin Code: </b> {viewData?.pincode}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="chat-about">
                <div className="chat-with">Joining Date</div>
                <div className="chat-num-messages">{viewData?.doj}</div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="chat-about">
                <div className="chat-with">Department</div>
                <div className="chat-num-messages">{viewData?.department}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToggleTab;
