import EmployerType from "../type/employerType";

export interface RegistrationAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  employerList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  totalApprovedKYC: 0,
  totalPendingKYC: 0,
  employerDetails: [],
};

const EmployerReducers = (
  state = INITIAL_STATE,
  action: RegistrationAction
) => {
  switch (action.type) {
    case EmployerType.CREATE_EMPLOYER:
      return {
        ...state,
      };

    case EmployerType.SET_EMPLOYER_LIST:
      return {
        ...state,
        employerList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
        totalApprovedKYC: action.payload.totalApprovedKYC,
        totalPendingKYC: action.payload.totalPendingKYC,
      };

    case EmployerType.UPDATE_EMPLOYER:
      return {
        ...state,
      };

    case EmployerType.UPDATE_EMPLOYER_KYC:
      return {
        ...state,
      };

    case EmployerType.GET_EMPLOYER_DETAILS:
      return {
        ...state,
        employerDetails: action.payload,
      };

    default:
      return state;
  }
};

export default EmployerReducers;
