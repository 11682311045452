import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import WebAction from "../stores/action/webAction";

type FormData = {
  name: string;
};

function AddCompanyTypeModal({
  setCatModal,
  catModal,
  type,
  editableData,
  getData,
}: any) {
  const handleClose = () => setCatModal(false);
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    if (type === "update" && editableData) {
      setValue("name", editableData.name);
    } else {
      setValue("name", "");
    }
  }, [type, editableData, catModal]);

  const formHandleSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (type === "add") {
        var companyTypeData = await dispatch(
          WebAction.addCompanyType({
            name: data.name,
          })
        );
      } else {
        var companyTypeData = await dispatch(
          WebAction.updateCompanyType({
            id: editableData.id,
            name: data.name,
          })
        );
      }

      if (companyTypeData?.status === false) {
        toast.error(companyTypeData.msg);
        setLoading(false);
      } else {
        setLoading(false);
        reset();
        handleClose();
        getData();
        toast.success(companyTypeData.msg);
      }
    } catch (error) {
      handleClose();
      setLoading(false);
    }
  };
  return (
    <div className="modal" style={{ display: catModal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form
            onSubmit={handleSubmit(formHandleSubmit)}
            className="needs-validation"
            encType="multipart/form-data">
            <div className="modal-header">
              <h5 className="modal-title">
                {type == "add" ? "ADD" : "Update"} Company Type{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  {...register("name")}
                  placeholder="Company Type Name"></input>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={handleClose}>
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4">
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                &nbsp; {type == "add" ? "Save" : "Update"} Company Type
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCompanyTypeModal;
