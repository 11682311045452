const AttendanceType = {
  GET_ATTENDANCE_LIST: "GET_ATTENDANCE_LIST",
  GET_EMPLOYEE_WISE_ATTENDANCE_LIST: "GET_EMPLOYEE_WISE_ATTENDANCE_LIST",
};

export interface GetAttendanceList {
  search: string;
  limit: number;
  page: number;
}

export interface GetEmployeeWiseAttendanceList {
  id: number;
  search: string;
  limit: number;
  page: number;
}

export default AttendanceType;
