import { Link } from "react-router-dom";
import Weblayout from "../../layout/Weblayout";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EmployeeAction from "../../stores/action/employeeAction";
import UpdateKYCModal from "../../components/employee/UpdateKYCModal";
import ApprovedKYCModal from "../../components/employee/ApprovedKYCModal";
import RightSideBarModal from "../../components/employee/RightSidebarModal";
import ToggleTab from "../../components/employee/ToggleTab";

function EmployeeList() {
  function menuCheck() {
    return false;
  }

  const [search, set_search] = useState<any>("");
  const [limit, set_limit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [toggle, setToggle] = useState<boolean>(false);
  const [kycModal, setKYCModal] = useState<boolean>(false);
  const [viewData, setViewData] = useState<any>();
  const [type, setType] = useState<any>();
  const [approveKYCModal, setApproveKYCModal] = useState<any>();
  const [editableData, setEditableData] = useState<any>([]);
  const dispatch = useDispatch<any>();

  const {
    employeeList,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
    totalApprovedKYC,
    totalPendingKYC,
  } = useSelector((state: RootState) => state.employee);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getEmployeeData = useCallback(async () => {
    await dispatch(
      EmployeeAction.getEmployeeList({
        limit: limit,
        search: search,
        page: page,
        type: type,
      })
    );
  }, [dispatch, limit, search, page, type]);

  useEffect(() => {
    getEmployeeData();
  }, [getEmployeeData, search, limit, page, type]);

  const toggleOpen = (id: any) => {
    setToggle(id);
  };

  const toggleClose = (id: any) => {
    setToggle(false);
  };

  const oepnUpdateKYCModal = (id: any) => {
    setKYCModal(true);
  };

  const openApproveKYCModal = (id: any) => {
    setApproveKYCModal(true);
  };

  return (
    <Weblayout pageName={"EmployeeList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">ASL Account List</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">ASL Account List</li>
                </ul>
              </div>
            </div>
          </div>
          <RightSideBarModal editableData={editableData} />
          <UpdateKYCModal
            modal={kycModal}
            setModal={setKYCModal}
            getEmployeeData={getEmployeeData}
            editableData={editableData}
          />
          <ApprovedKYCModal
            modal={approveKYCModal}
            setModal={setApproveKYCModal}
            getEmployeeData={getEmployeeData}
            editableData={editableData}
          />
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <button
                    className="btn btn-success"
                    onClick={() => setType("VERIFIED")}>
                    KYC Approved List{" "}
                    <span className="badge">{totalApprovedKYC}</span>
                  </button>{" "}
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-danger"
                    onClick={() => setType("NOT VERIFIED")}>
                    KYC Pending List{" "}
                    <span className="badge">{totalPendingKYC}</span>
                  </button>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => set_limit(e.target.value)}
                      id="defaultSelect"
                      className="form-select">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => set_search(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list">
                      <thead>
                        <tr>
                          <th className="center"></th>
                          <th className="center">#</th>
                          <th className="center"> EMP ID</th>
                          <th className="center"> Company Name </th>
                          <th className="center"> Name </th>
                          <th className="center"> Email </th>
                          <th className="center"> Mobile</th>
                          <th className="center"> Account Status</th>
                          <th className="center"> KYC Status</th>
                          <th className="center"> Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {employeeList?.length > 0 ? (
                          employeeList.map((dataList: any, index: number) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td className="center">
                                    <a
                                      href="#"
                                      onClick={() => {
                                        setViewData(dataList);
                                        toggleOpen(dataList.id);
                                        toggle
                                          ? toggleClose(dataList.id)
                                          : toggleOpen(dataList.id);
                                      }}>
                                      <i
                                        className={
                                          toggle
                                            ? "fa fa-minus-circle text-danger"
                                            : "fas fa-plus-circle text-success"
                                        }></i>
                                    </a>
                                  </td>
                                  <td>{index + 1}</td>
                                  <td>{dataList.emp_no}</td>
                                  <td>{dataList.companyName}</td>
                                  <td>{dataList.name}</td>
                                  <td>{dataList.email}</td>
                                  <td>{dataList.mobile}</td>
                                  <td>
                                    {dataList?.employee_status === "PENDING" ? (
                                      <a
                                        href="javascript:void(0);"
                                        className="label label-warning rounded-0">
                                        {dataList.employee_status}
                                      </a>
                                    ) : dataList?.employee_status ===
                                      "ACTIVE" ? (
                                      <a
                                        href="javascript:void(0);"
                                        className="label label-success rounded-0">
                                        {dataList.employee_status}
                                      </a>
                                    ) : dataList?.employee_status ===
                                      "INACTIVE" ? (
                                      <a
                                        href="javascript:void(0);"
                                        className="label label-danger rounded-0">
                                        {dataList.employee_status}
                                      </a>
                                    ) : null}
                                  </td>
                                  <td>
                                    {dataList?.kyc_status === "VERIFIED" ? (
                                      <a
                                        href="javascript:void(0);"
                                        className="label label-success rounded-0">
                                        {dataList.kyc_status}
                                      </a>
                                    ) : dataList?.kyc_status ===
                                      "SEND FOR APPROVAL" ? (
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          setEditableData(dataList);
                                          openApproveKYCModal(dataList.id);
                                        }}
                                        className="label label-warning rounded-0">
                                        {dataList.kyc_status}
                                      </a>
                                    ) : dataList?.kyc_status ===
                                      "NOT VERIFIED" ? (
                                      <a
                                        className="label label-danger rounded-0"
                                        onClick={() => {
                                          setEditableData(dataList);
                                          oepnUpdateKYCModal(dataList.id);
                                        }}>
                                        {dataList.kyc_status}
                                      </a>
                                    ) : dataList?.kyc_status === "REJECTED" ? (
                                      <a
                                        href="javascript:void(0);"
                                        className="label label-danger rounded-0">
                                        {dataList.kyc_status}
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <a
                                        className="label label-primary rounded-0"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRight"
                                        onClick={() => {
                                          setEditableData(dataList);
                                        }}
                                        aria-controls="offcanvasRight">
                                        <i
                                          className="fa fa-eye"
                                          style={{ fontSize: "14px" }}></i>{" "}
                                        View
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={10}>
                                    <ToggleTab
                                      toggle={toggle}
                                      viewData={viewData}
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {employeeList?.length > 0 ? (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto ">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto ">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className="dt-paging-button page-item disabled"
                              onClick={() => set_page(1)}>
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="First"
                                data-dt-idx="first">
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page === 1 ? "" : set_page(currentPage - 1)
                              }>
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous">
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>
                            {pagination.map((p) => {
                              return (
                                <li
                                  className={`dt-paging-button page-item ${
                                    currentPage === p ? "active" : ""
                                  }`}
                                  onClick={() => set_page(p)}>
                                  <a
                                    className="page-link"
                                    href="javascript:void(0);"
                                    aria-controls="tableGroup"
                                    aria-current="page"
                                    data-dt-idx="0">
                                    {p}
                                  </a>
                                </li>
                              );
                            })}

                            <li
                              className={`dt-paging-button page-item next ${
                                lastPage === page ? "disabled" : ""
                              }`}
                              onClick={() =>
                                lastPage === page
                                  ? ""
                                  : set_page(currentPage + 1)
                              }>
                              <a
                                href="javascript:void(0);"
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next">
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>
                            <li
                              className="dt-paging-button page-item"
                              onClick={() => set_page(lastPage)}>
                              <a
                                href="javascript:void(0);"
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-label="Last"
                                data-dt-idx="last">
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Weblayout>
  );
}

export default EmployeeList;
