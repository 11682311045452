import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import EmployeeType, {
  GetEmployeeList,
  GetEmployerDetails,
  UpdateEmployeeKYC,
  ApproveEmployeeKYC,
} from "../type/employeeType";
import toast from "react-hot-toast";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const EmployeeAction = {
  getEmployeeList:
    (data: GetEmployeeList): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "employee/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            type: data.type,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: EmployeeType.GET_EMPLOYEE_LIST,
          payload: {
            data: result.data.data.employees.data,
            currentPage: result.data.data.employees.current_page,
            lastPage: result.data.data.employees.last_page,
            totalData: result.data.data.employees.total,
            from: result.data.data.employees.from,
            to: result.data.data.employees.to,
            totalApprovedKYC: result.data.data.totalApprovedKYC,
            totalPendingKYC: result.data.data.totalPendingKYC,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateEmployeeKYC:
    (data: UpdateEmployeeKYC): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "employee/update-kyc",
          {
            id: data.id,
            pan_no: data.pan_no,
            pan_img: data.pan_img,
            aadhar_no: data.aadhar_no,
            aadhar_img: data.aadhar_img,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: EmployeeType.UPDATE_EMPLOYEE_KYC,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error) {
        console.log("error");
      }
    },

  approveEmployeeKYC:
    (data: ApproveEmployeeKYC): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "employee/approve-kyc",
          {
            id: data.id,
            kyc_status: data.kyc_status,
            kyc_reject_reason: data.kyc_reject_reason,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: EmployeeType.APPROVE_EMPLOYEE_KYC,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error) {
        console.log("error");
      }
    },

  getEmployerDetails:
    (data: GetEmployerDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "employer/details/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          }
        );

        dispatch({
          type: EmployeeType.GET_EMPLOYER_DETAILS,
          payload: result.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default EmployeeAction;
