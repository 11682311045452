import { Link } from "react-router-dom";
import Weblayout from "../../layout/Weblayout";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EnquiryAction from "../../stores/action/enquiryAction";

function ContactList() {
  function menuCheck() {
    return false;
  }

  const [search, set_search] = useState<any>("");
  const [limit, set_limit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [type, setType] = useState<any>();
  const dispatch = useDispatch<any>();

  const { contactList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.enquiry);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getContactList = useCallback(async () => {
    await dispatch(
      EnquiryAction.getContact({
        limit: limit,
        search: search,
        page: page,
        type: "CONTACT",
      })
    );
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getContactList();
  }, [getContactList, search, limit, page]);

  console.log(contactList);

  return (
    <Weblayout pageName={"Home"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Contact List</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">Contact List</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>
                    <strong>All</strong> Contact
                  </h2>
                  <ul className="header-dropdown m-r--5">
                    <li className="dropdown">
                      <Link
                        to="#"
                        onClick={menuCheck}
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <i className="material-icons">more_vert</i>
                      </Link>
                      <ul className="dropdown-menu float-end">
                        <li>
                          <Link to="#" onClick={menuCheck}>
                            Action
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={menuCheck}>
                            Another action
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={menuCheck}>
                            Something else here
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => set_limit(e.target.value)}
                      id="defaultSelect"
                      className="form-select">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => set_search(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list">
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center"> Name </th>
                          <th className="center"> Mobile </th>
                          <th className="center"> Email </th>
                          <th className="center"> Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactList?.length > 0 ? (
                          contactList.map((dataList: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{dataList.name}</td>
                                <td>{dataList.phone}</td>
                                <td>{dataList.email}</td>
                                <td>{dataList.message}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {contactList?.length > 0 ? (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto ">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto ">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className="dt-paging-button page-item disabled"
                              onClick={() => set_page(1)}>
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="First"
                                data-dt-idx="first">
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page === 1 ? "" : set_page(currentPage - 1)
                              }>
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous">
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>
                            {pagination.map((p) => {
                              return (
                                <li
                                  className={`dt-paging-button page-item ${
                                    currentPage === p ? "active" : ""
                                  }`}
                                  onClick={() => set_page(p)}>
                                  <a
                                    className="page-link"
                                    href="javascript:void(0);"
                                    aria-controls="tableGroup"
                                    aria-current="page"
                                    data-dt-idx="0">
                                    {p}
                                  </a>
                                </li>
                              );
                            })}

                            <li
                              className={`dt-paging-button page-item next ${
                                lastPage === page ? "disabled" : ""
                              }`}
                              onClick={() =>
                                lastPage === page
                                  ? ""
                                  : set_page(currentPage + 1)
                              }>
                              <a
                                href="javascript:void(0);"
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next">
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>
                            <li
                              className="dt-paging-button page-item"
                              onClick={() => set_page(lastPage)}>
                              <a
                                href="javascript:void(0);"
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-label="Last"
                                data-dt-idx="last">
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Weblayout>
  );
}

export default ContactList;
