const RegistrationType = {
  SET_REG_LIST: "SET_REG_LIST",
};

export interface GetRegistration {
  search: string;
  page: number;
  limit: number;
}

export default RegistrationType;
