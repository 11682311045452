import { useState } from "react";
import { useDispatch } from "react-redux";
import EmployeeAction from "../../stores/action/employeeAction";

function UpdateKYCModal({
  modal,
  setModal,
  getEmployeeData,
  editableData,
}: any) {
  const [loading, setLoading] = useState<any>();
  const [panNo, setPanNo] = useState<any>();
  const [panImg, setPanImg] = useState<any>();
  const [aadharNo, setAadharNo] = useState<any>();
  const [aadharImg, setAadharImg] = useState<any>();
  const dispatch = useDispatch<any>();
  const closeModal = () => {
    setModal(false);
  };

  const handlePanImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setPanImg(file);
    }
  };

  const handleAadharImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setAadharImg(file);
    }
  };

  const handelSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        EmployeeAction.updateEmployeeKYC({
          id: editableData.id,
          pan_no: panNo,
          pan_img: panImg,
          aadhar_no: aadharNo,
          aadhar_img: aadharImg,
        })
      );
      closeModal();
      getEmployeeData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <form onSubmit={handelSubmit}>
            <div className="modal-header">
              <h5 className="modal-title">Update Employee KYC Details</h5>
              <button
                type="button"
                onClick={closeModal}
                className="btn-close"></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      PAN Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter PAN Number"
                      value={panNo}
                      onChange={(e) => setPanNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      Aadhar Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Aadhar Number"
                      value={aadharNo}
                      onChange={(e) => setAadharNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      Upload PAN Image <span className="text-danger">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={handlePanImageChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      Upload Aadhar Image<span className="text-danger">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={handleAadharImageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={closeModal}>
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4">
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                &nbsp; Update KYC Details
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateKYCModal;
