import { log } from "console";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const menuList = [
  {
    name: "Dashboard",
    subMenu: false,
    active: true,
    subMenuList: [],
    url: "/dashboard",
    icon: "fa fa-home",
  },
  // {
  //   name: "Member Management",
  //   subMenu: true,
  //   active: true,
  //   icon: "user",
  //   subMenuList: [
  //     {
  //       name: "Member List",
  //       active: true,
  //       url: "/member-list",
  //     },
  //     {
  //       name: "New Joining",
  //       active: true,
  //       url: "/new-joining",
  //     },
  //   ],
  // },

  {
    name: "Manage Enquiry",
    subMenu: true,
    active: true,
    icon: "fa fa-envelope",
    subMenuList: [
      {
        name: "Enquiry List",
        active: true,
        url: "/enquiry-list",
      },
      {
        name: "Contact List",
        active: true,
        url: "/contact-list",
      },
    ],
  },

  {
    name: "Manage EMC Account",
    subMenu: true,
    active: true,
    icon: "fa fa-user",
    subMenuList: [
      {
        name: "EMC Account List",
        active: true,
        url: "/employer-list",
      },
    ],
  },

  {
    name: "Manage ASL Account",
    subMenu: true,
    active: true,
    icon: "fas fa-user-friends",
    subMenuList: [
      {
        name: "ASL Account List",
        active: true,
        url: "/employee-list",
      },
    ],
  },

  {
    name: "Manage Attendance",
    subMenu: true,
    active: true,
    icon: "fas fa-clipboard",
    subMenuList: [
      {
        name: "Attendance List",
        active: true,
        url: "/attendance-list",
      },
    ],
  },

  {
    name: "Setting",
    subMenu: true,
    active: true,
    icon: "fa fa-cog",
    subMenuList: [
      {
        name: "Certification",
        active: true,
        url: "/certification",
      },
      {
        name: "Download Document",
        active: true,
        url: "/document",
      },
      {
        name: "Company Type",
        active: true,
        url: "/company-type",
      },
    ],
  },
];

function Sidebar() {
  const [activeTab, setActiveTab] = useState<any>({
    parent: "Dashboard",
    child: "",
    icon: "",
  });

  const handleMenu = (value1: any, value2: any, value3: any) => {
    setActiveTab({
      parent: value1,
      child: value2,
      icon: value3,
    });

    localStorage.setItem(
      "Menus",
      JSON.stringify({
        menu: value1,
        subMenu: value2,
        icon: value3,
      })
    );
  };

  useEffect(() => {
    const getMenus = localStorage.getItem("Menus");
    if (getMenus) {
      const parsedMenu = JSON.parse(getMenus);
      if (parsedMenu) {
        setActiveTab({
          parent: parsedMenu.menu,
          child: parsedMenu.subMenu,
          icon: parsedMenu.icon,
        });
      }
    }
  }, []);

  return (
    <div>
      <aside id="leftsidebar" className="sidebar">
        <div className="menu">
          <ul className="list">
            {menuList.map((item: any, menuIndex: number) => {
              return (
                <>
                  <li
                    onClick={() => handleMenu(item.name, "", item.icon)}
                    className={activeTab.parent === item.name ? "active" : ""}>
                    <Link
                      to={!item.subMenu ? `${item.url}` : "#"}
                      className={item.subMenu ? "menu-toggle" : ""}>
                      <i className={item.icon}></i>
                      <span>{item.name}</span>
                    </Link>
                  </li>
                  {item.subMenu ? (
                    <ul
                      className="ml-menu"
                      style={{
                        display:
                          activeTab.parent === item.name ? "block" : "none",
                      }}>
                      {item.subMenuList.map((subItem: any, menuIndex: any) => {
                        return (
                          <li
                            onClick={() =>
                              handleMenu(item.name, subItem.name, item.icon)
                            }>
                            <Link
                              to={`${subItem.url}`}
                              style={{
                                color:
                                  activeTab.child === subItem.name
                                    ? "red"
                                    : "black",
                              }}>
                              {subItem.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </>
              );
            })}
          </ul>
        </div>
      </aside>

      <aside id="rightsidebar" className="right-sidebar">
        <ul className="nav nav-tabs tab-nav-right">
          <li role="presentation">
            <Link to="#skins" data-bs-toggle="tab" className="active">
              SKINS
            </Link>
          </li>
          <li role="presentation">
            <Link to="#settings" data-bs-toggle="tab">
              SETTINGS
            </Link>
          </li>
        </ul>
        <div className="tab-content">
          <div
            role="tabpanel"
            className="tab-pane in active in active stretchLeft"
            id="skins">
            <div className="demo-skin">
              <div className="rightSetting">
                <p>SIDEBAR COLOR</p>
                <div className="selectgroup selectgroup-pills sidebar-color mt-3">
                  <label className="selectgroup-item">
                    <input
                      type="radio"
                      name="icon-input"
                      value="1"
                      className="btn-check selectgroup-input select-sidebar"
                      checked
                    />
                    <span
                      className="selectgroup-button selectgroup-button-icon"
                      data-toggle="tooltip"
                      data-original-title="Light Sidebar">
                      <i className="fas fa-sun"></i>
                    </span>
                  </label>
                  <label className="selectgroup-item">
                    <input
                      type="radio"
                      name="icon-input"
                      value="2"
                      className="btn-check selectgroup-input select-sidebar"
                    />
                    <span
                      className="selectgroup-button selectgroup-button-icon"
                      data-toggle="tooltip"
                      data-original-title="Dark Sidebar">
                      <i className="fas fa-moon"></i>
                    </span>
                  </label>
                </div>
              </div>
              <div className="rightSetting">
                <p>THEME COLORS</p>
                <div
                  className="btn-group theme-color mt-3"
                  role="group"
                  aria-label="Basic radio toggle button group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    value="1"
                    id="btnradio1"
                    checked
                  />
                  <label className="radio-toggle btn btn-outline-primary">
                    Light
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    value="2"
                    id="btnradio2"
                  />
                  <label className="radio-toggle btn btn-outline-primary ">
                    Dark
                  </label>
                </div>
              </div>
              <div className="rightSetting">
                <p>SKINS</p>
                <ul className="demo-choose-skin choose-theme list-unstyled">
                  <li data-theme="black">
                    <div className="black-theme"></div>
                  </li>
                  <li data-theme="white">
                    <div className="white-theme white-theme-border"></div>
                  </li>
                  <li data-theme="purple">
                    <div className="purple-theme"></div>
                  </li>
                  <li data-theme="blue">
                    <div className="blue-theme"></div>
                  </li>
                  <li data-theme="cyan">
                    <div className="cyan-theme"></div>
                  </li>
                  <li data-theme="green">
                    <div className="green-theme"></div>
                  </li>
                  <li data-theme="orange">
                    <div className="orange-theme"></div>
                  </li>
                </ul>
              </div>
              <div className="rightSetting">
                <p>RTL Layout</p>
                <div className="switch mt-3">
                  <label>
                    <input type="checkbox" className="layout-change" />
                    <span className="lever switch-col-red layout-switch"></span>
                  </label>
                </div>
              </div>
              <div className="rightSetting">
                <p>DISK SPACE</p>
                <div className="sidebar-progress">
                  <div className="progress m-t-20">
                    <div className="progress-bar l-bg-cyan shadow-style width-per-45"></div>
                  </div>
                  <span className="progress-description">
                    <small>26% remaining</small>
                  </span>
                </div>
              </div>
              <div className="rightSetting">
                <p>Server Load</p>
                <div className="sidebar-progress">
                  <div className="progress m-t-20">
                    <div className="progress-bar l-bg-orange shadow-style width-per-63"></div>
                  </div>
                  <span className="progress-description">
                    <small>Highly Loaded</small>
                  </span>
                </div>
              </div>
              <div className="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-border-radius btn-restore-theme">
                  Restore Default
                </button>
              </div>
            </div>
          </div>
          <div role="tabpanel" className="tab-pane stretchRight" id="settings">
            <div className="demo-settings">
              <p>GENERAL SETTINGS</p>
              <ul className="setting-list">
                <li>
                  <span>Report Panel Usage</span>
                  <div className="switch">
                    <label>
                      <input type="checkbox" checked />
                      <span className="lever switch-col-green"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span>Email Redirect</span>
                  <div className="switch">
                    <label>
                      <input type="checkbox" />
                      <span className="lever switch-col-blue"></span>
                    </label>
                  </div>
                </li>
              </ul>
              <p>SYSTEM SETTINGS</p>
              <ul className="setting-list">
                <li>
                  <span>Notifications</span>
                  <div className="switch">
                    <label>
                      <input type="checkbox" checked />
                      <span className="lever switch-col-purple"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span>Auto Updates</span>
                  <div className="switch">
                    <label>
                      <input type="checkbox" checked />
                      <span className="lever switch-col-cyan"></span>
                    </label>
                  </div>
                </li>
              </ul>
              <p>ACCOUNT SETTINGS</p>
              <ul className="setting-list">
                <li>
                  <span>Offline</span>
                  <div className="switch">
                    <label>
                      <input type="checkbox" checked />
                      <span className="lever switch-col-red"></span>
                    </label>
                  </div>
                </li>
                <li>
                  <span>Location Permission</span>
                  <div className="switch">
                    <label>
                      <input type="checkbox" />
                      <span className="lever switch-col-lime"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
