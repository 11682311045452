const EmployeeType = {
  GET_EMPLOYEE_LIST: "GET_EMPLOYEE_LIST",
  UPDATE_EMPLOYEE_KYC: "UPDATE_EMPLOYEE_KYC",
  APPROVE_EMPLOYEE_KYC: "APPROVE_EMPLOYEE_KYC",
  GET_EMPLOYER_DETAILS: "GET_EMPLOYER_DETAILS",
};

export interface GetEmployeeList {
  search: string;
  limit: number;
  page: number;
  type: string;
}

export interface UpdateEmployeeKYC {
  id: number;
  pan_no: any;
  pan_img: any;
  aadhar_no: any;
  aadhar_img: any;
}

export interface ApproveEmployeeKYC {
  id: number;
  kyc_status: any;
  kyc_reject_reason: string;
}

export interface GetEmployerDetails {
  id: number;
}

export default EmployeeType;
