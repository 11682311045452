import { useCallback, useEffect, useState } from "react";
import WebAction from "../../stores/action/webAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EmployerAction from "../../stores/action/employerAction";

function UpdateEmployerModal({
  modal,
  setModal,
  editableData,
  getEmployerList,
}: any) {
  const { getStateList } = useSelector((state: RootState) => state.web);
  const { getDistrictList } = useSelector((state: RootState) => state.web);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [mobile, setMobile] = useState<any>();
  const [employee, setEmployee] = useState<any>();
  const [state, setState] = useState<any>();
  const [district, setDistrict] = useState<any>();
  const [pincode, setPincode] = useState<any>();
  const [address, setAddress] = useState<any>();
  const dispatch = useDispatch<any>();
  const closeModal = () => {
    setModal(false);
  };

  const setData = useCallback(() => {
    setName(editableData?.name);
    setEmail(editableData?.email);
    setMobile(editableData?.mobile);
    setEmployee(editableData?.no_of_employee);
    setState(editableData?.state);
    setDistrict(editableData?.district);
    setPincode(editableData?.pincode);
    setAddress(editableData?.address);
  }, [
    editableData?.name,
    editableData?.email,
    editableData?.mobile,
    editableData?.no_of_employee,
    editableData?.state,
    editableData?.district,
    editableData?.pincode,
    editableData?.address,
  ]);

  useEffect(() => {
    setData();
  }, [setData]);

  const formHandleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await dispatch(
        EmployerAction.updateEmployer({
          id: editableData.id,
          name: name,
          email: email,
          mobile: mobile,
          no_of_employee: employee,
          state: state,
          district: district,
          pincode: pincode,
          address: address,
        })
      );

      getEmployerList();
      closeModal();
    } catch (error) {
      closeModal();
    }
  };

  const getStateData = useCallback(async () => {
    await dispatch(WebAction.getState());
  }, [dispatch]);

  const getDistricData = useCallback(async () => {
    await dispatch(
      WebAction.getDistrict({
        state_id: state,
      })
    );
  }, [dispatch, state]);

  useEffect(() => {
    getDistricData();
    getStateData();
  }, [getStateData, getDistricData]);

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <form
            className="needs-validation"
            onSubmit={formHandleSubmit}
            encType="multipart/form-data">
            <div className="modal-header">
              <h5 className="modal-title">Update Employer Details</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"></input>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      placeholder="Email Id"></input>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      className="form-control"
                      placeholder="Mobile No"></input>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={employee}
                      onChange={(e) => setEmployee(e.target.value)}
                      placeholder="No Of Employee"></input>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <select
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      className="form-control">
                      <option value="" disabled>
                        Select State
                      </option>
                      {getStateList &&
                        getStateList?.map((stateData: any) => {
                          return (
                            <option key={stateData.id} value={stateData.id}>
                              {stateData.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <select
                      value={district}
                      onChange={(e) => setDistrict(e.target.value)}
                      className="form-control">
                      <option value="" disabled>
                        Select District
                      </option>
                      {getDistrictList &&
                        getDistrictList?.map((districtData: any) => {
                          return (
                            <option
                              key={districtData.id}
                              value={districtData.id}>
                              {districtData.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      className="form-control"
                      placeholder="Pin Code"></input>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="form-control"
                      placeholder="Address"></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={closeModal}>
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4">
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                &nbsp; Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateEmployerModal;
