import React from "react";
import { CKEditor as Editor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface MyEditorProps {
  initialValue: string;
  onChange: (newValue: string) => void;
}

const MyEditor: React.FC<MyEditorProps> = ({ initialValue, onChange }) => {
  return (
    <Editor
      editor={ClassicEditor as any} // <-- Type casting here
      data={initialValue}
      config={{
        ckfinder: {
          uploadUrl: "http://localhost/asl-api/api/v1/admin/upload-image",
        },

        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
          ],
        },
      }}
      onChange={(event: any, editor: any) => {
        const data = editor.getData();
        onChange(data);
      }}
    />
  );
};

export default MyEditor;
