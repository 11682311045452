import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import axios from "axios";
import toast from "react-hot-toast";
import WebType, {
  AddAbout,
  AddCertificate,
  AddCompanyType,
  AddDocument,
  DeleteCertificate,
  DeleteCompanyType,
  DeleteDocument,
  GetCertification,
  GetCompanyType,
  GetDistrict,
  GetDocument,
  UpdateCertificate,
  UpdateCompanyType,
  UpdateDocument,
} from "../type/webType";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const WebAction = {
  addAbout:
    (data: AddAbout): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "about-us",
          {
            title: data.title,
            meta: data.meta,
            content: data.content,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.SET_ADD_ABOUT,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },

  getAboutUs:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(BASE_URL + "get-about-us", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });
        dispatch({
          type: WebType.SET_ABOUT_US,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

  addCertificate:
    (data: AddCertificate): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "certification",
          {
            title: data.title,
            image: data.image,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.ADD_CERTIFICATION,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },

  getCertification:
    (data: GetCertification): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "get-certification",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: WebType.SET_CERTIFICATION_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        console.log(error);
      }
    },

  updateCertificate:
    (data: UpdateCertificate): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "update-certification",
          {
            id: data.id,
            title: data.title,
            image: data.image,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.UPDATE_CERTIFICATION,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },

  deleteCertificate:
    (data: DeleteCertificate): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "delete-certification",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.DELETE_CERTIFICATION,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },

  addDocument:
    (data: AddDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "add-document",
          {
            title: data.title,
            image: data.image,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.ADD_DOCUMENT,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },

  getDocument:
    (data: GetDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "get-document",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: WebType.SET_DOCUMENT_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        console.log(error);
      }
    },

  updateDocument:
    (data: UpdateDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "update-document",
          {
            id: data.id,
            title: data.title,
            image: data.image,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.UPDATE_DOCUMENT,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },

  deleteDocument:
    (data: DeleteDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "delete-document",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.DELETE_DOCUMENT,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },

  getState:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(BASE_URL + "state", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });
        dispatch({
          type: WebType.GET_STATE_LIST,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

  getDistrict:
    (data: GetDistrict): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "district",
          {
            state_id: data.state_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: WebType.GET_DISTRICT_LIST,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    },

  addCompanyType:
    (data: AddCompanyType): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "add-company-type",
          {
            name: data.name,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.ADD_COMPANY_TYPE,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },

  getCompanyType:
    (data: GetCompanyType): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "get-company-type",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: WebType.SET_COMPANY_TYPE_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        console.log(error);
      }
    },

  updateCompanyType:
    (data: UpdateCompanyType): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "update-company-type",
          {
            id: data.id,
            name: data.name,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.UPDATE_COMPANY_TYPE,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },

  deleteCompanyType:
    (data: DeleteCompanyType): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "delete-company-type",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: WebType.DELETE_COMPANY_TYPE,
        });
        toast.success(result.data.msg);
      } catch (error) {
        console.log("error");
      }
    },
};

export default WebAction;
