import { Link } from "react-router-dom";
import Weblayout from "../../layout/Weblayout";
import { useCallback, useEffect, useState } from "react";
import AddCertification from "../../components/AddCertification";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import WebAction from "../../stores/action/webAction";

function Certification() {
  const { getCertificationList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.web);

  const [catModal, setCatModal] = useState<boolean>(false);
  const [modelType, setModelType] = useState<any>("add");
  const [editableData, setEditableData] = useState<string>("add");
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch<any>();
  const modalOpen = (type: string) => {
    setCatModal(true);
    setModelType(type);
  };

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getData = useCallback(async () => {
    await dispatch(
      WebAction.getCertification({
        limit: limit,
        search: search,
        page: page,
      })
    );
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getData();
  }, [getData, search, limit, page]);

  const deleteData = async (id: number) => {
    await dispatch(WebAction.deleteCertificate({ id: id }));
    getData();
  };

  return (
    <Weblayout pageName={"Certification"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Certification List</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">Certification List</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>
                    <strong>All</strong> Certification
                  </h2>
                  <div className="header-dropdown m-r--5">
                    {catModal && (
                      <AddCertification
                        setCatModal={setCatModal}
                        catModal={catModal}
                        type={modelType}
                        editableData={editableData}
                        getData={getData}
                      />
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => modalOpen("add")}>
                      ADD Certification
                    </button>
                  </div>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      id="defaultSelect"
                      className="form-select"
                      onChange={(e) => setLimit(e.target.value)}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        onChange={(e) => setSearch(e.target.value)}
                        className="form-control"
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list">
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center"> Title </th>
                          <th className="center"> Image </th>
                          <th className="center"> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getCertificationList?.length > 0 ? (
                          getCertificationList.map(
                            (dataList: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{dataList.title}</td>
                                  <td>
                                    <iframe src={dataList.image}></iframe>
                                  </td>
                                  <td>
                                    <a
                                      onClick={() => {
                                        modalOpen("update");
                                        setEditableData(dataList);
                                      }}
                                      href="javascript:void(0);">
                                      <i className="fa fa-edit "></i>
                                    </a>{" "}
                                    |{" "}
                                    <a
                                      onClick={() => deleteData(dataList.id)}
                                      className="text-danger"
                                      href="javascript:void(0);">
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {getCertificationList?.length > 0 ? (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto ">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto ">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className="dt-paging-button page-item disabled"
                              onClick={() => setPage(1)}>
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="First"
                                data-dt-idx="first">
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page === 1 ? "" : setPage(currentPage - 1)
                              }>
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous">
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>
                            {pagination.map((p) => {
                              return (
                                <li
                                  className={`dt-paging-button page-item ${
                                    currentPage === p ? "active" : ""
                                  }`}
                                  onClick={() => setPage(p)}>
                                  <a
                                    className="page-link"
                                    href="javascript:void(0);"
                                    aria-controls="tableGroup"
                                    aria-current="page"
                                    data-dt-idx="0">
                                    {p}
                                  </a>
                                </li>
                              );
                            })}

                            <li
                              className={`dt-paging-button page-item next ${
                                lastPage === page ? "disabled" : ""
                              }`}
                              onClick={() =>
                                lastPage === page
                                  ? ""
                                  : setPage(currentPage + 1)
                              }>
                              <a
                                href="javascript:void(0);"
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next">
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>
                            <li
                              className="dt-paging-button page-item"
                              onClick={() => setPage(lastPage)}>
                              <a
                                href="javascript:void(0);"
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-label="Last"
                                data-dt-idx="last">
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Weblayout>
  );
}

export default Certification;
